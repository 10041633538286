define("client/mixins/droppable", ["exports", "client/utils/nventor"], function (_exports, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Mixin.create((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _obj = {
    // classNameBindings: ['dropPositionClassName'],
    classNameBindings: ['_isDragOver:is-drag-over', 'dropPositionClassName'],
    droppable: true,
    disableWhenReadonly: false,
    hasLeft: false,
    didInsertElement() {
      // @TODO: in glimmer, element needs to be passed in
      this.set('currentElement', this.element);

      // this.element.addEventListener('mouseenter', this.handleMouseEnter)
      // this.element.addEventListener('mouseleave', this.handleMouseLeave)
    },
    // @action
    // handleMouseEnter () {
    //   console.log('mouse enter')
    //   this.set('hasLeft', false)
    // },

    // @action
    // handleMouseLeave () {
    //   console.log('mouse leave')
    //   this.set('hasLeft', true)
    // },

    isDroppable: Ember.computed('droppable', 'disableWhenReadonly', function () {
      if (this.disableWhenReadonly) {
        return false;
      }
      return this.droppable;
    }),
    _isDragOver: Ember.computed('isDragOver', 'isDroppable', function () {
      if (!this.isDroppable) {
        return false;
      }
      return this.isDragOver;
    }),
    getTransferData(event) {
      let data = event.dataTransfer.getData('text/data');
      data = _nventor.default.safeParseJSON(data);
      return data;
    },
    dropPositionClassName: Ember.computed('dropPosition', 'isDragOver', function () {
      if (!this.isDragOver) {
        return '';
      }
      return this.dropPosition;
    }),
    dragLeave(event) {
      event.preventDefault();
      this.set('hasLeft', true);
      if (!this.isDroppable) {
        return false;
      }
      this.set('dragLeaveEvent', event);
      Ember.run.debounce(this, this._dragLeave, 100);
    },
    _dragLeave() {
      if (!this.hasLeft) {
        return;
      }
      this.set('isDragOver', false);
      const event = this.dragLeaveEvent;
      const targetElement = event.target;
      const dropContainerElement = targetElement.closest('.drop-container');
      if (!dropContainerElement.classList.contains('is-drag-over')) {
        return;
      }
    },
    dragOver(event) {
      event.preventDefault();
      if (!this.isDroppable) {
        return false;
      }
      this.set('hasLeft', false);
      const targetElement = event.target;
      if (this.isDragOver) {
        return;
      }
      const dragDetail = this.outerComponent?.dragDetail;
      const modelIdPath = this.modelIdPath;
      const model = this.model;
      if (dragDetail?.get && modelIdPath && model?.get) {
        const dragModelId = dragDetail.get(modelIdPath);
        const dropModelId = model.get(modelIdPath);
        if (dragModelId === dropModelId) {
          return;
        }
      }
      const dropContainerElement = targetElement.closest('.drop-container');
      if (dropContainerElement.classList.contains('is-drag-over')) {
        return;
      }
      this.set('isDragOver', true);
      if (this.onDragOver) {
        this.onDragOver(dropContainerElement, event);
      }
    },
    drop(event) {
      event.preventDefault();
      if (this.isDroppable) {
        this.set('isDragOver', false);
        const data = this.getTransferData(event);
        if (this.onDrop) {
          this.onDrop(data, event);
        }
      }
    }
  }, _applyDecoratedDescriptor(_obj, "dragLeave", [_dec], Object.getOwnPropertyDescriptor(_obj, "dragLeave"), _obj), _applyDecoratedDescriptor(_obj, "_dragLeave", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_dragLeave"), _obj), _applyDecoratedDescriptor(_obj, "dragOver", [_dec3], Object.getOwnPropertyDescriptor(_obj, "dragOver"), _obj), _obj));
});