define("client/pods/components/settings/settings-editor/payments-editor/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let SettingsSettingsEditorPaymentsEditorComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service('contacts'), _dec4 = Ember._action, _dec5 = Ember.computed('args.model._data.shopPaymentMethods.length'), _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember.computed('args.model._data.tw_payments_payuni.paymentMethods.@each'), _dec11 = Ember._action, _class = class SettingsSettingsEditorPaymentsEditorComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "contactsService", _descriptor3, this);
      this.crud.addLists(this, ['paymentMethods/creditCards/banks', 'paymentMethods/installmentPeriods', 'paymentMethods/creditCards/banks', 'paymentMethods/shop', 'payments/linepay/lists/providers', 'extensions/apps/tw/payuni-payment-methods', {
        name: 'workflows/lists/items/activities/templates',
        appendPath: 'authorization'
      }]);
    }
    setDefaultProvider(providerModel) {
      const model = this.args.model || {};
      if (RA.isNotNilOrEmpty(model)) {
        model.setLinePayDefaultProvider(providerModel);
      }
    }
    get shopPaymentMethodsDefaultsList() {
      const paymentMethods = R_.dotPath('crud.lists.paymentMethods-shop')(this) || [];
      const allowedPaymentMethods = this.args.model?._data?.shopPaymentMethods || [];
      return R.filter(method => R.includes(method.getData('value'), allowedPaymentMethods), paymentMethods);
    }
    get ecPayInstallmentPeriods() {
      const periods = this.crud.lists['paymentMethods-installmentPeriods'] || [];
      const allowed = [3, 6, 12, 18, 24];
      return R.filter(period => {
        return R.includes(period?._data?.value)(allowed);
      })(periods);
    }
    setDefaultVirtualAccount(defaultVirtualAccountModel) {
      //Reset virtual accounts
      const settingsModel = this.args.model;
      if (settingsModel) {
        settingsModel.setBspDefaultVirtualAccount(defaultVirtualAccountModel);
      }
    }
    selectBank(key, bank) {
      const bankLabel = bank.getData('label');
      const model = this.args.model;
      model.setData(`${key}.bankLabel`, bankLabel);
    }
    toggleEcPayPayments() {
      const model = this.args.model;
      const enabledCreditCard = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledCreditCard'])(model);
      const enabledCreditCardInstallments = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledCreditCardInstallments'])(model);
      const enabledVirtualAccount = R.pathOr(false, ['_data', 'tw_payments_ecPay', 'enabledVirtualAccount'])(model);
      model.setData('tw_payments_ecPay.enabled', enabledCreditCard || enabledCreditCardInstallments || enabledVirtualAccount);
    }
    setShopPaymentMethodsDefault(model = {}) {
      const shopPaymentMethodsDefaultsList = this.shopPaymentMethodsDefaultsList || [];
      const currentDefault = model.getData('shopPaymentMethodsDefault');
      let resetDefault = false;
      if (!R.includes(currentDefault, shopPaymentMethodsDefaultsList)) {
        resetDefault = true;
      }
      if (!currentDefault) {
        resetDefault = true;
      }
      if (resetDefault) {
        const first = shopPaymentMethodsDefaultsList.get('firstObject');
        if (first) {
          model.setData('shopPaymentMethodsDefault', first.getData('value'));
        } else {
          model.setData('shopPaymentMethodsDefault', '');
        }
      }
    }
    get isPayuniCreditCardEnabled() {
      const model = this.args.model;
      const paymentMethods = model?._data?.tw_payments_payuni?.paymentMethods || [];
      return R.includes('creditCard', paymentMethods);
    }
    validateShopVirtualAccountExpiryDays(value) {
      if (Number.isNaN(Number(value))) {
        const message = 'must be a number';
        const translatedMessage = this.intl.t(message);
        window.alert(translatedMessage);
      }
      const settingsModel = this.args.model;
      const isPayuniEnabled = R.pathEq(['_data', 'tw_payments_payuni', 'enabled'], true)(settingsModel);
      if (!isPayuniEnabled) {
        return;
      }
      const payuniPaymentMethods = R.pathOr([], ['_data', 'tw_payments_payuni', 'paymentMethods'])(settingsModel);
      if (!payuniPaymentMethods.includes('virtualAccount')) {
        return;
      }
      const maxExpiryDays = 180;
      if (Number(value) > maxExpiryDays) {
        const message = 'the expiry date cannot be more than';
        const translatedMessage = this.intl.t(message, {
          date: maxExpiryDays
        });
        window.alert(translatedMessage);
        settingsModel.setData('shopVirtualAccountExpiryDays', maxExpiryDays);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactsService", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDefaultProvider", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultProvider"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shopPaymentMethodsDefaultsList", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "shopPaymentMethodsDefaultsList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDefaultVirtualAccount", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultVirtualAccount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectBank", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "selectBank"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEcPayPayments", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEcPayPayments"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setShopPaymentMethodsDefault", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "setShopPaymentMethodsDefault"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isPayuniCreditCardEnabled", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "isPayuniCreditCardEnabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validateShopVirtualAccountExpiryDays", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "validateShopVirtualAccountExpiryDays"), _class.prototype), _class);
});