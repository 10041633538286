define("client/pods/components/files/files-browser/component", ["exports", "ember-concurrency", "ramda", "ramda-adjunct"], function (_exports, _emberConcurrency, R, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    applicationService: Ember.inject.service('application'),
    intl: Ember.inject.service(),
    tagName: '',
    resource: 'files',
    resourceKey: 'files',
    allowUploads: true,
    filtersAdapterName: 'uploads/filters',
    resultsProperty: 'uploads',
    adapterName: 'uploads',
    selectedItems: null,
    selectedTagKeys: null,
    untagSelectedTagKeys: false,
    hasSelectedItemsLimit: false,
    selectedItemsLimit: 0,
    init() {
      this._super(...arguments);
      const resultsProperty = this.resultsProperty;
      let filters = this.filters;
      if (!filters) {
        const filters = this.search.setupFilters({
          adapterName: this.filtersAdapterName,
          defaultFiltersData: {
            resultsProperty
          }
        });
      }
      this.set('uploadsFilters', filters);
      this.set('selectedItems', []);
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.crud.addLists(this, ['uploads/lists/tags']);
      if (R.isEmpty(this.selectedItems) && RA.isNotNilOrEmpty(this.parentSelectedItems)) {
        this.selectedItems.addObjects(this.parentSelectedItems);
      }
      if (this.fetchUploadsOnInit) {
        this.fetchUploadsTask.perform();
      }
    },
    showPaginationInFooter: Ember.computed('isSidebar', 'applicationService.isMobile', function () {
      if (this.applicationService.isMobile) {
        return false;
      }
      if (this.isSidebar) {
        return false;
      }
      return true;
    }),
    hasSelectedItems: Ember.computed('parentSelectedItems.length', 'selectedItems.length', function () {
      if (RA.isNotNilOrEmpty(this.selectedItems)) {
        return true;
      }
      if (RA.isNotNilOrEmpty(this.parentSelectedItems)) {
        return true;
      }
      return false;
    }),
    isRunning: Ember.computed('removeTask.isRunning', 'onSubmit.isRunning', 'onSubmitTask.isRunning', function () {
      const isRemoveTaskRunning = R.pathOr(false, ['removeTask', 'isRunning'])(this);
      const isOnSubmitTaskRunning = R.pathOr(false, ['onSubmit', 'isRunning'])(this);
      const isOnSubmitTaskTaskRunning = R.pathOr(false, ['onSubmitTask', 'isRunning'])(this);
      if (isRemoveTaskRunning || isOnSubmitTaskRunning || isOnSubmitTaskTaskRunning) {
        return true;
      }
      return false;
    }),
    uploadBtnLabel: Ember.computed('resource', function () {
      if (this.applicationService.isMobile) {
        return this.intl.t('upload');
      }
      let resource = this.resource;
      if (resource) {
        resource = this.intl.t(resource);
      }
      return `${this.intl.t('upload')}: ${resource}`;
    }),
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this._super(...arguments);
        this.resetSelectedItems();
      }
    },
    resetSelectedItems(item) {
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
      this.set('selectedItems', []);
    },
    fetchUploadsTask: (0, _emberConcurrency.task)(function* () {
      // @TODO: auto select images after upload? would first need the files that were uploaded, which is not avaliable currently
      if (this.uploadsFilters?._data?.tagKey) {
        return yield this.crud.searchRecordsTask.perform({
          adapterName: this.adapterName,
          filters: this.uploadsFilters
        });
      }
      return yield this.crud.searchRecordsTask.perform({
        adapterName: this.adapterName,
        filters: this.uploadsFilters,
        data: {
          resource: this.resource,
          resourceKey: this.resourceKey
        }
      });
    }),
    removeTask: (0, _emberConcurrency.task)(function* (files) {
      files = RA.ensureArray(files);
      const isDeleted = yield this.onRemove(files, {
        onAfter: this.fetchUploadsTask
      });
      if (!isDeleted) {
        return;
      }
      const uploadsKeys = R.map(R.path(['_data', '_key']))(files);
      const selectedItems = R.pipe(R.propOr([], 'selectedItems'), R.reject(R.pipe(R.path(['_data', '_key']), R.contains(R.__, uploadsKeys))))(this);
      this.set('selectedItems', selectedItems);
      yield this.fetchUploadsTask.perform();
    }),
    onSubmitTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, showPreviewModal) {
      if (this.allowMultipleItems) {
        yield this.onSubmit(this.selectedItems);
        this.resetSelectedItems();
      } else {
        const uploadsModel = R.path(['selectedItems', 0])(this);
        yield this.onSubmit(uploadsModel);
      }
      if (onCloseDialogAction && !onCloseDialogAction?.type) {
        onCloseDialogAction();
      }
    }),
    selectedItemIndexes: Ember.computed('selectedItems.[]', function () {
      let index = 0;
      const selectedItems = this?.selectedItems || [];
      return R.map(item => {
        index = index + 1;
        return {
          uuid: item?._data?.uuid,
          index
        };
      })(selectedItems);
    }),
    addToSelected(item, toggleModel) {
      const isOverLimit = this?.selectedItems?.length >= this.selectedItemsLimit;
      if (this.hasSelectedItemsLimit && isOverLimit) {
        if (toggleModel.toggleIsOn) {
          item.isSelected = false;
        } else {
          this.selectedItems.removeObject(item);
        }
        return;
      }
      const allowMultipleItems = R.propOr(false, 'allowMultipleItems')(this);
      if (!allowMultipleItems) {
        this.resetSelectedItems(item);
        this.selectedItems.clear();
        this.selectedItems.addObject(item);
        // Reset selectedTagKeys
        this.selectedItems[0].set('isSelected', true);
        this.onSelectItem(item);
      } else {
        if (this.selectedItems.includes(item)) {
          this.selectedItems.removeObject(item);
          this.onDeselectItem(item);
        } else {
          this.selectedItems.addObject(item);
          this.onSelectItem(item);
        }
      }
      this.set('lastSelected', item);
    },
    addToSelectedAndCloseDialog(item, onCloseDialogAction, toggleModel) {
      this.addToSelected(item, toggleModel);
      onCloseDialogAction();
    },
    showPreview(file) {
      this.set('showPreviewModal', file);
    },
    resetSelectedTagKeys() {
      this.set('selectedTagKeys', []);
      this.set('untagSelectedTagKeys', false);
    },
    cancel() {
      this.resetSelectedItems();
      this.onCancel();
    }
  }, _applyDecoratedDescriptor(_obj, "addToSelected", [_dec], Object.getOwnPropertyDescriptor(_obj, "addToSelected"), _obj), _applyDecoratedDescriptor(_obj, "addToSelectedAndCloseDialog", [_dec2], Object.getOwnPropertyDescriptor(_obj, "addToSelectedAndCloseDialog"), _obj), _applyDecoratedDescriptor(_obj, "showPreview", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showPreview"), _obj), _applyDecoratedDescriptor(_obj, "resetSelectedTagKeys", [_dec4], Object.getOwnPropertyDescriptor(_obj, "resetSelectedTagKeys"), _obj), _applyDecoratedDescriptor(_obj, "cancel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "cancel"), _obj), _obj));
});