define("client/pods/payment-methods/shop/adapter", ["exports", "@ember-data/adapter", "client/utils/nventor", "client/mixins/adapters-new", "client/pods/options/model", "ramda-adjunct", "ramda"], function (_exports, _adapter, _nventor, _adaptersNew, _model, RA, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    modelObj: _model.default,
    idParam: false,
    cacheList: false,
    paymentsService: Ember.inject.service('payments'),
    findAll() {
      let data = [{
        value: this.constants.paymentMethods.creditCard,
        hasPaymentTerms: false,
        label: 'credit card',
        isNotEditable: true
      }, {
        value: this.constants.paymentMethods.cash,
        hasPaymentTerms: false,
        label: 'cash',
        isNotEditable: true
      }, {
        value: this.constants.paymentMethods.cashBeforeDelivery,
        hasPaymentTerms: false,
        label: 'cash before delivery',
        isNotEditable: true
      }, {
        value: this.constants.paymentMethods.linePay,
        hasPaymentTerms: false,
        label: 'line pay',
        isNotEditable: true,
        isThirdParty: true
      }, {
        value: this.constants.paymentMethods.applePay,
        hasPaymentTerms: false,
        label: 'apple pay',
        isNotEditable: true,
        isThirdParty: true
      }, {
        value: this.constants.paymentMethods.googlePay,
        hasPaymentTerms: false,
        label: 'google pay',
        isNotEditable: true,
        isThirdParty: true
      }, {
        value: this.constants.paymentMethods.samsungPay,
        hasPaymentTerms: false,
        label: 'samsung pay',
        isNotEditable: true,
        isThirdParty: true
      }];
      // Add index to data array
      data = RA.mapIndexed((methodData = {}, index) => {
        return R.mergeRight(methodData, {
          index: ++index
        });
      })(data);

      // Filter out un-enabled payment methods. eg LinePay, ApplePay, GooglePay, SamsungPay
      const validPaymentMethods = this.paymentsService.validatePaymentMethods(data);
      return _nventor.default.resolve(validPaymentMethods);
    }
  });
});