define("client/pods/components/dispatch/panels-display/print-all-dialog/component", ["exports", "ramda", "ember-concurrency", "ramda-extension", "ramda-adjunct"], function (_exports, R, _emberConcurrency, R_, RA) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    dispatch: Ember.inject.service(),
    constants: Ember.inject.service(),
    settings: Ember.inject.service(),
    constantsService: Ember.inject.service('constants'),
    tagName: 'span',
    attributeBindings: ['attr:data-trigger-id'],
    attr: '',
    hasPrices: true,
    hasGroups: true,
    hasReturnsDetails: true,
    nextDispatchProcessCode: '',
    isPrinted: false,
    hidePrinted: 'hidePrintedAll',
    markAsPrintedOnClose: true,
    waybillsOptionsByProvider: null,
    printType: '',
    lastPrintTimestamp: '',
    batchStatus: null,
    waybillsPerPage: 4,
    previousWaybillsPerPage: 4,
    refreshWaybills: Math.random(),
    init() {
      this.crud.addLists(this, ['documents/lists/hide-printed', 'documents/lists/print', 'documents/lists/print/unit', 'documents/lists/print/product-code', 'extensions/apps/tw/t-cat/lists/waybill-print-type', 'extensions/apps/tw/t-cat/accounts/filters']);
      this._super(...arguments);
      const subPanels = this.printSubPanels || [];
      if (subPanels.length > 0) {
        this.tabsManager.displaySubPanel(this, subPanels.get('firstObject'));
      }
      const dispatchProcesses = R_.dotPath('crud.lists.dispatch-lists-processes')(this) || [];
      this.set('dispatchProcesses', dispatchProcesses);
      this.set('isPrinted', false);
      this.set('errors', []);
      const printImagesOnDocuments = this?.settings?.settingsModel?._data?.printImagesOnDocuments || false;
      if (printImagesOnDocuments) {
        this.set('hasImages', true);
      }
      const twShippingPayuni = this.settings?.settingsModel?._data?.tw_shipping_payuni || {};
      const subTabFilters = this.search.setupFilters({
        adapterName: 'transporter/filters/sub-tab',
        defaultFiltersData: {
          tw_shipping_payuni: twShippingPayuni
        }
      });
      this.set('subTabFilters', subTabFilters);
    },
    afterLoadLists() {
      const hidePrinted = R.pathOr([], ['crud', 'lists', 'documents-lists-hide-printed', 0, '_data', 'value'])(this);
      this.set('hidePrinted', hidePrinted);
    },
    setupTask: (0, _emberConcurrency.task)(function* (onFetchUnpaginatedData) {
      this.set('isPrinted', false);
      this.set('markAsPrintedOnClose', true);
      this.set('errors', []);
      return yield onFetchUnpaginatedData({
        hidePrinted: this.hidePrinted
      });
    }).drop(),
    printBadge: Ember.computed('toPrintList.[]', 'hasSelected', function () {
      if (RA.isNotNilOrEmpty(this.hasSelected)) {
        const models = this.toPrintList || [];
        return models.length;
      }
      return this.intl.t('all');
    }),
    printContentResults: Ember.computed('toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.{@each.isActive,[]}', 'crud.lists.extensions-apps-tw-t-cat-accounts-filters', 'subTabFilters._data.transporterAccount', 'subTabFilters._data.transporterType', 'refreshWaybills', 'dispatchProcess._data.allowPrintAllWaybills', function () {
      const refreshWaybills = this.refreshWaybills; // this does nothing, just refresh waybills list after fetching

      const activeSubTab = R.pipe(R.pathOr([], ['tab', 'subTabsArray']), R.find(R.propEq('isActive', true)))(this);
      let provider = R.propOr('', 'value')(activeSubTab);
      const isHct = R.pipe(R.propOr('', 'label'), R.includes(this.constantsService.transporterExtensions.hct))(activeSubTab);
      if (isHct) {
        provider = this.constantsService.transporterExtensions.hct;
      }
      const allResultsWhen = ['pickingList', 'deliveryNotes', 'hctSummary'];
      const shouldShowAllResults = R.includes(provider)(allResultsWhen);
      if (shouldShowAllResults) {
        return this.toPrintModelsList;
      }
      return R.pipe(R.propOr([], 'toPrintModelsList'), R.filter(R.pathEq(['_data', 'transporterExtension'], provider)), results => {
        if (RA.isNilOrEmpty(activeSubTab)) {
          return results;
        }
        const hasFilters = this._subTabHasFilters(activeSubTab);
        if (!hasFilters) {
          return results;
        }
        return this._filterResultsBySubTabFilters({
          type: 'printContentResults',
          results
        });
      })(this);
    }),
    printContentListToPrint: Ember.computed('toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.{@each.isActive,[]}', 'crud.lists.extensions-apps-tw-t-cat-accounts-filters', 'subTabFilters._data.transporterAccount', 'subTabFilters._data.transporterType', 'dispatchProcess._data.allowPrintAllWaybills', function () {
      const activeSubTab = R.pipe(R.pathOr([], ['tab', 'subTabsArray']), R.find(R.propEq('isActive', true)))(this);
      let provider = R.propOr('', 'value')(activeSubTab);
      const isHct = R.pipe(R.propOr('', 'label'), R.includes(this.constantsService.transporterExtensions.hct))(activeSubTab);
      if (isHct) {
        provider = this.constantsService.transporterExtensions.hct;
      }
      const allResultsWhen = ['pickingList', 'deliveryNotes', 'hctSummary'];
      const shouldShowAllResults = R.includes(provider)(allResultsWhen);
      if (shouldShowAllResults) {
        return this.toPrintList;
      }
      return R.pipe(R.propOr([], 'toPrintList'), R.filter(R.anyPass([R.pathEq(['model', '_data', 'transporterExtension'], provider), R.pathEq(['model', '_data', 'transporterExtension'], provider)])), results => {
        if (RA.isNilOrEmpty(activeSubTab)) {
          return results;
        }
        const hasFilters = this._subTabHasFilters(activeSubTab);
        if (!hasFilters) {
          return results;
        }
        return this._filterResultsBySubTabFilters({
          type: 'printContentListToPrint',
          results
        });
      })(this);
    }),
    printCount: Ember.computed('printContentResults', 'toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.{@each.isActive,[]}', function () {
      return R.length(this.printContentResults);
    }),
    isHCTorTCatPrintBtnDisabled: Ember.computed('printContentResults', 'toPrintList.[]', 'toPrintListTCat.[]', 'tab.subTabsArray.{@each.isActive,[]}', 'allUnpaginated.[]', function () {
      const activeSubTab = this.tabsManager.getActiveSubTab(this.tab);
      const activeSubTabValue = R.propOr('', 'value')(activeSubTab);
      if (activeSubTabValue === 'hctSummary') {
        const allUnpaginatedLength = R.length(this?.allUnpaginated || []);
        return R.lte(allUnpaginatedLength, 0);
      }
      const printContentResultsLength = R.length(this?.printContentResults || []);
      return R.lte(printContentResultsLength, 0);
    }),
    printContentResultsData: Ember.computed('printContentResults', function () {
      const printContentResults = this?.printContentResults || [];
      return R.map(printContentResult => {
        return printContentResult?._data || {};
      })(printContentResults);
    }),
    printListWithTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printContentResultsData.@each.transporterWaybillRecord', 'tab.subTabsArray.{@each.isActive,[]}', function () {
      return R.pipe(R.propOr([], 'printContentResults'), R.reject(R.pipe(R.path(['_data', 'transporterWaybillRecord']), RA.isNilOrEmpty)))(this);
    }),
    printListWithTransporterWaybillRecordData: Ember.computed('printListWithTransporterWaybillRecord', function () {
      const printListWithTransporterWaybillRecord = this?.printListWithTransporterWaybillRecord || [];
      return R.map(transporterWaybillRecord => {
        return transporterWaybillRecord?._data || {};
      })(printListWithTransporterWaybillRecord);
    }),
    printListCountTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printListWithTransporterWaybillRecordData.@each.transporterWaybillRecord', 'printContentResults', function () {
      return R.length(this.printListWithTransporterWaybillRecord);
    }),
    missingTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'printContentResultsData.@each.transporterWaybillRecord', 'tab.subTabsArray.{@each.isActive,[]}', function () {
      return R.pipe(R.propOr([], 'printContentResults'), R.filter(R.pipe(R.path(['_data', 'transporterWaybillRecord']), RA.isNilOrEmpty)))(this);
    }),
    missingTransporterWaybillRecordData: Ember.computed('missingTransporterWaybillRecord', function () {
      const missingTransporterWaybillRecord = this?.missingTransporterWaybillRecord || [];
      return R.map(transporterWaybillRecord => {
        return transporterWaybillRecord?._data || {};
      })(missingTransporterWaybillRecord);
    }),
    printCountMissingTransporterWaybillRecord: Ember.computed('lastPrintTimestamp', 'missingTransporterWaybillRecordData.@each.transporterWaybillRecord', 'printContentResults', function () {
      return R.length(this.missingTransporterWaybillRecord);
    }),
    showMarkAsPrintedBtn: Ember.computed('isDispatched', 'isPrinted', 'dispatchProcess._data.allowPrintAllWaybills', function () {
      const allowPrintAllWaybills = R.pathEq(['dispatchProcess', '_data', 'allowPrintAllWaybills'], true)(this);
      if (allowPrintAllWaybills) {
        return this.isPrinted;
      }
      return this.isDispatched && this.isPrinted;
    }),
    printSubPanels: Ember.computed('isDispatched', 'crud.lists.dispatch-lists-print-all-sub-panels.[]', 'dispatchProcess._data.allowPrintAllWaybills', 'printContentResults', function () {
      const subPanels = R_.dotPath('crud.lists.dispatch-lists-print-all-sub-panels')(this) || [];
      const isDispatched = this.isDispatched;
      const allowPrintAllWaybills = R.pathEq(['dispatchProcess', '_data', 'allowPrintAllWaybills'], true)(this);
      const resultsGroupByTransporterExtension = R.pipe(R.pathOr([], ['printContentResults']), R.groupBy(R.path(['_data', 'transporterExtension'])))(this);
      if (isDispatched || allowPrintAllWaybills) {
        // "dispatch/lists/print-all-sub-panels"
        return R.map(subPanel => {
          const transporterExtension = R.pathOr('', ['_data', 'transporterExtension'])(subPanel);
          const documentsCount = R.pipe(R.pathOr([], [transporterExtension]), R.length)(resultsGroupByTransporterExtension);
          if (documentsCount === 0) {
            return subPanel;
          }
          const data = subPanel.serialize();
          data.badge = documentsCount;
          return this.crud.setupNewRecord({
            adapterName: 'dispatch/lists/print-all-sub-panels',
            data
          });
        })(subPanels);
      }
      return subPanels.filter(model => {
        const allowedPanels = ['pickingList', 'deliveryNotes'];
        return allowedPanels.includes(model?._data?.value);
      });
    }),
    updateBtnLabel: Ember.computed('nextDispatchProcessCode', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      let label = this.intl.t('update status');
      if (nextDispatchProcessCode) {
        const dispatchService = this.dispatch;
        const dispatchProcesses = this.dispatchProcesses || [];
        const nextDispatchProcess = dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);
        if (nextDispatchProcess) {
          let name = nextDispatchProcess.getData('name');
          if (nextDispatchProcess.getData('translate')) {
            name = this.intl.t(name);
          }
          label = `${label}: ${name}`;
        }
      }
      return label;
    }),
    isDisableSetNextDispatchProcess: Ember.computed('nextDispatchProcessCode', 'dispatchProcess', function () {
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      const dispatchProcess = this.dispatchProcess;
      if (nextDispatchProcessCode && dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        return false;
      }
      return true;
    }),
    disablePrintBtn: Ember.computed('toPrintList.@each.model._data.{waybill,transporterWaybillRecord}', 'printListWithTransporterWaybillRecord', 'printListCountTransporterWaybillRecord', 'lastPrintTimestamp', function () {
      // Should only allow to print if all documents contains waybills
      const allDocHaveWaybill = R.pipe(R.propOr([], 'toPrintList'), R.map(R.pathOr('', ['model', '_data', 'waybill'])), R.reject(RA.isNilOrEmpty), R.allPass([RA.isNotNilOrEmpty]))(this);
      if (allDocHaveWaybill) {
        return false;
      }
      return true;
    }),
    printCountOuterRegion: Ember.computed('toPrintListOuterRegion', function () {
      const toPrintListOuterRegion = R.pipe(R.prop('toPrintListOuterRegion'), R.length)(this);
      return toPrintListOuterRegion;
    }),
    toPrintListOuterRegion: Ember.computed('toPrintList.@each.model._data.transporterRegionIsOuter', 'tab.subTabsArray.@each.isActive', 'tab.subTabsArray.[]', 'lastPrintTimestamp', function () {
      const printCountOuterRegion = R.pipe(R.prop('toPrintList'), R.filter(R.pathEq(['model', '_data', 'transporterRegionIsOuter'], true)))(this);
      return printCountOuterRegion;
    }),
    setNextDispatchProcessTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction) {
      // const models = this.toPrintModelsList
      const dispatchService = this.dispatch;
      const filters = this.filters;
      const dispatchProcesses = this.dispatchProcesses || [];
      const dispatchProcess = this.dispatchProcess;
      const nextDispatchProcessCode = this.nextDispatchProcessCode;
      if (dispatchProcess.getData('code') !== nextDispatchProcessCode) {
        const nextDispatchProcess = dispatchService.findDispatchProcess(dispatchProcesses, nextDispatchProcessCode);
        if (nextDispatchProcess) {
          const filtersData = filters.serialize();
          const defaultFiltersData = R.mergeRight(filtersData, {
            matches: [],
            count: 5000,
            dispatchProcessesCriterias: dispatchProcess.getData('criterias'),
            dispatchProcess: dispatchProcess.getData('code'),
            sort: filters.getData('sort')
          });
          if (this.period) {
            defaultFiltersData.dispatchDateZ = this.period.date;
          } else {
            defaultFiltersData.dispatchDateZ = '';
          }
          let results;
          if (RA.isNilOrEmpty(this.hasSelected)) {
            const unpaginatedFilters = this.search.setupFilters({
              adapterName: 'documents/dispatch/filters',
              defaultFiltersData
            });
            results = yield this.get('dispatch.updateEntireDispatchProcessTask').perform(unpaginatedFilters, nextDispatchProcess);
          } else {
            const toPrintList = this?.toPrintList || [];
            const models = R.map(document => {
              return document?.model || {};
            })(toPrintList);
            results = yield this.get('dispatch.setDispatchProcessTask').perform(models, nextDispatchProcess);
          }
          this.onRefresh(nextDispatchProcess);
          onCloseDialogAction();
          return results;
        }
        onCloseDialogAction();
      }
    }).drop(),
    selectSubTabTask: (0, _emberConcurrency.task)(function* (subTab, a, b) {
      // Reset all filters when changing tabs
      this.subTabFilters.reset({
        resetExcludes: ['tw_shipping_payuni']
      });
      if (subTab.getData('value') === 'hctSummary') {
        // fetch all unpaginated
        const allResults = yield this.onFetchUnpaginatedData({
          setResults: false,
          hidePrinted: false
        });
        const allUnpaginated = R.prop('docs')(allResults) || [];
        this.set('allUnpaginated', allUnpaginated);
      }
      return this.tabsManager.displaySubPanel(this, subTab);
    }),
    generateWaybillsTask: (0, _emberConcurrency.task)(function* ({
      onPrintDialogAction,
      subTab,
      isNewWaybill = false,
      onlyFetch = false,
      onlyGenerate = false,
      onlyMissingTransporterWaybillRecord = false,
      onlyWithTransporterWaybillRecord = false,
      waybillsPerPage
    } = {}) {
      const isTCat = R.pipe(R.prop('value'), R.includes(this.constantsService.transporterExtensions.tCat))(subTab);
      let provider = R.propOr('', 'value')(subTab);
      const isHct = R.pipe(R.propOr('', 'label'), R.includes(this.constantsService.transporterExtensions.hct))(subTab);
      if (isHct) {
        provider = this.constantsService.transporterExtensions.hct;
      }
      const isAllowTransporter = R.anyPass([R.includes(this.constantsService.transporterExtensions.tCat), R.includes(this.constantsService.transporterExtensions.hct), R.includes(this.constantsService.transporterExtensions.sevenEleven), R.includes(this.constantsService.transporterExtensions.payuniSevenEleven)])(provider);
      let documents = this.printContentResults;
      if (isTCat) {
        return this.dispatch.tCatGenerateAndDownloadWaybillTask.perform({
          component: this,
          documents,
          printType: this.printType
        });
      } else if (isAllowTransporter) {
        if (onlyMissingTransporterWaybillRecord) {
          documents = this.missingTransporterWaybillRecord;
        } else if (onlyWithTransporterWaybillRecord) {
          documents = this.printListWithTransporterWaybillRecord;
        }
        const filtersData = this.subTabFilters._data || {};
        const response = yield this.dispatch.generateAndDownloadWaybillsTask.perform({
          component: this,
          transporterExtension: provider,
          documents,
          isNewWaybill,
          onlyFetch,
          onlyGenerate,
          waybillsPerPage,
          filtersData
        });
        this.set('batchStatus', response);
        this.set('refreshWaybills', Math.random());
        this.handleSevenElevenWaybillResponse({
          provider,
          response
        });
        const lastPrintTimestamp = new Date().getTime();
        this.set('lastPrintTimestamp', lastPrintTimestamp);
        return response;
      }
      if (subTab.value !== 'hctSummary') {
        this.set('isPrinted', true);
      }
      if (onPrintDialogAction) {
        onPrintDialogAction(subTab);
      }
    }),
    handleSevenElevenWaybillResponse({
      response,
      provider
    }) {
      const transporterExtensionsToDisplayAsIframe = [this.constants.transporterExtensions.sevenEleven, this.constants.transporterExtensions.payuniSevenEleven];
      if (!transporterExtensionsToDisplayAsIframe.includes(provider)) {
        return;
      }
      const waybillsOptionsByProvider = {
        [provider]: {}
      };
      response = response?.data || {};
      if (response?.B2C || response?.C2C) {
        waybillsOptionsByProvider[provider].htmlData = response;
        this._setWaybillsOptionsByProviderData(waybillsOptionsByProvider);
        R.forEachObjIndexed((transporterAccountHtmlData, transporterType) => {
          R.forEachObjIndexed((batch, transporterAccount) => {
            const transporterTypeLower = transporterType?.toLowerCase?.() || '';
            const transporterAccountLower = transporterAccount?.toLowerCase?.() || '';
            const popupTitle = `${this.intl.t(provider)} - ${this.intl.t(transporterTypeLower)} - ${this.intl.t(transporterAccountLower)}`;
            let updatedHtml = this.mergeWaybillBatch({
              batch,
              transporterType
            });
            const defaultWaybillsPerPage = 1;
            let waybillsPerPageC2C = this?.waybillsPerPage || defaultWaybillsPerPage;
            if (provider === this.constants.transporterExtensions.payuniSevenEleven) {
              waybillsPerPageC2C = this?.subTabFilters?._data?.tw_shipping_payuni?.sevenEleven?.waybillsPerPageC2C || defaultWaybillsPerPage;
            }
            if (transporterType === 'C2C' && waybillsPerPageC2C === defaultWaybillsPerPage) {
              updatedHtml = this._convertMultipleWaybillsPerPageToSingle(updatedHtml);
            }
            this.setupPopup(updatedHtml, {
              popupTitle
            });
          })(transporterAccountHtmlData);
        })(response);
      }
    },
    mergeWaybillBatch({
      batch,
      transporterType
    }) {
      batch = RA.ensureArray(batch);
      let htmlElement;
      if (transporterType === 'C2C') {
        htmlElement = this._mergeWaybillBatchHtml({
          batch,
          mainContainerSelector: '#Panel1'
        });
        htmlElement = this._updateHtmlRelativeUrlPath(htmlElement);
      } else if (transporterType === 'B2C') {
        htmlElement = this._mergeWaybillBatchHtml({
          batch,
          mainContainerSelector: '.mainContent'
        });
      }
      return htmlElement?.outerHTML || '';
    },
    _mergeWaybillBatchHtml({
      batch,
      mainContainerSelector
    }) {
      return R.pipe(R.reduce((acc, html) => {
        if (!acc) {
          const mainHtmlElement = document.createElement('html');
          mainHtmlElement.innerHTML = html;
          return mainHtmlElement;
        }
        const mainContainer = acc.querySelector(mainContainerSelector);
        const htmlElement = document.createElement('html');
        htmlElement.innerHTML = html;
        const childrenContainer = htmlElement.querySelector(mainContainerSelector);
        if (!childrenContainer) {
          return acc;
        }
        const containerChildren = Array.from(childrenContainer?.children);
        containerChildren.forEach(child => {
          mainContainer.appendChild(child);
        });
        return acc;
      }, null))(batch);
    },
    _convertMultipleWaybillsPerPageToSingle(html) {
      const htmlElement = document.createElement('html');
      htmlElement.innerHTML = html;
      const headElement = htmlElement.querySelector('head');
      const style = document.createElement('style');
      style.innerHTML = `
    div#Panel1 > table > tbody > tr > td {
        display: block !important;
        /*page-break-after: always !important;*/
        border-style: none !important;
    }
    p {
        page-break-after: avoid !important;
    }
    `;
      headElement.appendChild(style);

      // Apply page break to td
      const tdDivs = htmlElement.querySelectorAll('td > div');
      tdDivs.forEach((tdDiv, index) => {
        const td = tdDiv.parentElement;
        let updatedStyle = 'break-after: avoid !important;';
        const orgStyle = td.getAttribute('style');
        if (orgStyle) {
          updatedStyle = `${orgStyle}; break-after: avoid !important;`;
        }
        if (index < tdDivs.length - 1) {
          updatedStyle += 'page-break-after: always !important;';
        }
        td.setAttribute('style', updatedStyle);
      });
      return htmlElement.outerHTML;
    },
    _updateHtmlRelativeUrlPath(htmlElement) {
      const linkElements = htmlElement.querySelectorAll('[src], [href]');
      linkElements.forEach(linkElement => {
        if (linkElement.hasAttribute('src')) {
          const src = linkElement.getAttribute('src');
          linkElement.src = `https://epayment.7-11.com.tw/C2C/C2CWeb/${src}`;
        } else if (linkElement.hasAttribute('href')) {
          const href = linkElement.getAttribute('href');
          linkElement.href = `https://epayment.7-11.com.tw/C2C/C2CWeb/${href}`;
        }
      });
      return htmlElement;
    },
    fetchWaybillRecordTask: (0, _emberConcurrency.task)(function* (subTab) {
      let documents = this.availableToReDownloadDocuments;
      let provider = R.propOr('', 'value')(subTab);
      const waybillsPerPage = this.waybillsPerPage;
      const filtersData = this.subTabFilters._data || {};
      const response = yield this.dispatch.hctFetchWaybillRecordTask.perform({
        component: this,
        transporterExtension: provider,
        documents,
        waybillsPerPage,
        filtersData
      });
      this.handleSevenElevenWaybillResponse({
        provider,
        response
      });
      this.set('batchStatus', response);
      const lastPrintTimestamp = new Date().getTime();
      this.set('lastPrintTimestamp', lastPrintTimestamp);
      return response;
    }),
    resetBatchStatus() {
      this.set('batchStatus', null);
    },
    setWaybillsPerPage(model, {
      showConfirmation = true,
      defaultWaybillsPerPage
    } = {}) {
      if (defaultWaybillsPerPage) {
        this.set('waybillsPerPage', defaultWaybillsPerPage);
      }
      const activeSubTab = this.tabsManager.getActiveSubTab(this.tab);
      const provider = R.propOr('', 'transporterExtension')(activeSubTab);
      const waybillsOptionsByProvider = R.pathOr({}, ['waybillsOptionsByProvider', provider])(this);
      const waybillsPerPage = R.pathOr(4, ['_data', 'value'])(model);
      if (waybillsOptionsByProvider.waybillsPerPage === waybillsPerPage) {
        return;
      }
      const message = this.intl.t('please press print again to see changes');
      if (waybillsOptionsByProvider?.html && showConfirmation && !window.confirm(message)) {
        this.set('waybillsPerPage', this.previousWaybillsPerPage);
        return;
      }
      this.set('previousWaybillsPerPage', waybillsPerPage);
      const waybillsOptionsByProviderUpdated = R.pathOr({}, ['waybillsOptionsByProvider'])(this);
      if (showConfirmation) {
        waybillsOptionsByProviderUpdated[provider] = R.pipe(R.pathOr({}, [provider]), R.omit(['html']))(waybillsOptionsByProviderUpdated);
      }
      this._setWaybillsOptionsByProviderData(waybillsOptionsByProviderUpdated);
    },
    // @deprecated
    // should be refactored
    // this may print same waybills even tho others are selected
    _setWaybillsOptionsByProviderData(data) {
      this.set('waybillsOptionsByProvider', null);
      this.set('waybillsOptionsByProvider', data);
    },
    fetchUnPaginatedDataTask: (0, _emberConcurrency.task)(function* (onFetchUnPaginatedDataTask, model) {
      return yield onFetchUnPaginatedDataTask.perform({
        hidePrinted: model?._data?.value
      });
    }),
    setupPopup(html, {
      popupTitle = ''
    } = {}) {
      if (!html) {
        return html;
      }
      const popupWindow = window.open('', '_blank', 'width=800,height=600');
      popupWindow.document.write(html);
      if (popupTitle) {
        popupWindow.document.title = popupTitle;
      }
      popupWindow.document.close();
      if (popupWindow) {
        popupWindow.addEventListener('load', () => {
          const fetchImage = (imageElement, isRetry = false) => {
            const parent = imageElement.parentElement;
            const src = imageElement?.src;
            if (!src) {
              return Promise.resolve(false);
            }
            if (imageElement.naturalWidth > 0) {
              // was loaded successfully
              return Promise.resolve(true);
            }

            // refetch again and append as srcset
            return fetch(src).then(response => response.blob()).then(blob => {
              if (isRetry) {
                const retryBtnContainers = parent.querySelectorAll('.retry-btn-container');
                retryBtnContainers.forEach(retryBtnContainer => {
                  retryBtnContainer.remove();
                });
              }
              imageElement.srcset = URL.createObjectURL(blob);
              return true;
            }).catch(error => {
              const retryBtnContainer = popupWindow.document.createElement('div');
              retryBtnContainer.className = 'retry-btn-container';
              const retryBtn = popupWindow.document.createElement('button');
              retryBtn.textContent = this.intl.t('refetch barcode');
              retryBtn.className = 'retry-btn'; // Add a class if needed
              retryBtn.style.color = 'red';
              retryBtn.style.marginTop = '10px';
              retryBtn.onclick = () => {
                fetchImages(true);
              };
              retryBtnContainer.appendChild(retryBtn);
              parent.appendChild(retryBtnContainer);
              return false;
            });
          };
          const fetchImages = (isRetry = false) => {
            const imageElements = popupWindow.document.querySelectorAll('img');
            // fetch image using js fetch

            const imagesArray = Array.from(imageElements) || [];
            const promises = imagesArray.map(imageElement => {
              return fetchImage(imageElement, isRetry);
            });
            return Promise.all(promises);
          };
          fetchImages().then(fetchedAll => {
            if (R.includes(false)(fetchedAll)) {
              const errorMsg = this.intl.t('error fetching barcode');
              popupWindow.alert(errorMsg);
              return;
            }
            popupWindow.print();
          });
        });
      } else {
        alert('please allow popups to open');
      }
    },
    fetchWaybillRecordOrPrintCurrentTask: (0, _emberConcurrency.task)(function* (subTab) {
      return yield this.fetchWaybillRecordTask.perform(subTab);
    }),
    actions: {
      setPrintType(printType) {
        this.printType = R.pathOr(printType, ['_data', 'value'])(printType);
      },
      print(onPrintDialogAction, subTab) {
        if (subTab.value !== 'hctSummary') {
          this.set('isPrinted', true);
        }
        const allowedGenerateWaybills = [this.constantsService.transporterExtensions.tCat, this.constantsService.transporterExtensions.sevenEleven, this.constantsService.transporterExtensions.payuniSevenEleven];
        const isAllowToGenerateWaybills = allowedGenerateWaybills.includes(subTab.value);
        if (isAllowToGenerateWaybills) {
          const waybillsPerPage = this.waybillsPerPage;
          return this.generateWaybillsTask.perform({
            onPrintDialogAction,
            subTab,
            isNewWaybill: true,
            waybillsPerPage
          });
        }
        onPrintDialogAction(subTab);
      }
    },
    deliveryNotePrintFormat: Ember.computed('settings.settingsModel._data.deliveryNotePrintFormat', function () {
      return this?.settings?.settingsModel?._data?.deliveryNotePrintFormat || 'default';
    }),
    showUnit: Ember.computed('settings.settingsModel._data.showUnit', function () {
      return R.pathOr('showUnitBelowItems', ['settings', 'settingsModel', '_data', 'showUnit'])(this);
    }),
    showProductCode: Ember.computed('settings.settingsModel._data.showProductCode', function () {
      return R.pathOr('showCodeBelowItems', ['settings', 'settingsModel', '_data', 'showProductCode'])(this);
    }),
    isDeliveryNotesSubTab: Ember.computed('tab.subTabs.@each.isActive', function () {
      const tab = this.tab;
      const activeSubTab = this.tabsManager.getActiveSubTab(tab);
      if (activeSubTab.value === 'deliveryNotes') {
        return true;
      }
      return false;
    }),
    allowToPrint: Ember.computed('tab.subTabs[].isActive', 'tab.subTabsArray.@each.isActive', 'waybillsOptionsByProvider', 'printContentResults.length', function () {
      if (!this.printContentResults.length) {
        return false;
      }
      const activeSubTab = this.tabsManager.getActiveSubTab(this.tab);
      const transporterExtension = activeSubTab?.transporterExtension || '';
      if (!transporterExtension) {
        return true;
      }
      if (this?.waybillsOptionsByProvider?.[transporterExtension]?.html) {
        return true;
      }
      const canDownloadWaybills = R.pipe(R.pathOr([], ['printContentResults']), R.filter(R.pipe(R.path(['_data', 'waybill']), RA.isNotNilOrEmpty)))(this);
      if (canDownloadWaybills.length === this.printContentResults.length) {
        return true;
      }
      return false;
    }),
    availableToReDownloadDocuments: Ember.computed('printContentResults.length', function () {
      return R.pipe(R.pathOr([], ['printContentResults']), R.filter(R.pipe(R.path(['_data', 'waybill']), RA.isNotNilOrEmpty)))(this);
    }).volatile(),
    _filterResultsBySubTabFilters({
      type,
      results
    }) {
      if (RA.isNilOrEmpty(type)) {
        return results;
      }
      const allowedTransporters = [this.constants.transporterExtensions.tCat, this.constants.transporterExtensions.payuniSevenEleven];
      const transporterType = this.subTabFilters?._data?.transporterType;
      if (transporterType !== 'all') {
        results = this._filterSubTabResultByTransporterType(transporterType, results);
      }
      const allowedDispatchStatus = [this.constantsService.dispatchStatus.dispatched];
      const all = [];
      const filterTransporterAccount = this.subTabFilters?._data?.transporterAccount;
      const accounts = R_.dotPath('crud.lists.extensions-apps-tw-t-cat-lists-thermosphere')(this) || [];
      const allowPrintAllWaybills = R.pathOr(false, ['dispatchProcess', '_data', 'allowPrintAllWaybills'])(this);
      if (RA.isNilOrEmpty(accounts)) {
        return results;
      }
      R.forEach(accountModel => {
        const account = accountModel.getData('value');
        if (filterTransporterAccount !== 'all' && filterTransporterAccount !== account) {
          return false;
        }
        R.forEach(result => {
          let resultProxy = result || {};
          if (type === 'printContentListToPrint') {
            resultProxy = result?.model || {};
          }
          if (RA.isNilOrEmpty(resultProxy)) {
            return false;
          }
          const transporterExtension = resultProxy?._data?.transporterExtension || '';
          const dispatchStatus = resultProxy?._data?.dispatchStatus || false;
          const transporterAccount = resultProxy?._data?.transporterAccount || 'all';
          const _key = resultProxy?._data?._key || '';
          const filterByTransporters = R.includes(transporterExtension)(allowedTransporters);
          const filterByDispatchStatus = R.includes(dispatchStatus)(allowedDispatchStatus);
          if (allowPrintAllWaybills || filterByTransporters && filterByDispatchStatus) {
            if (transporterAccount === account) {
              const existInArray = R.find(filteredResult => {
                let resultProxy = filteredResult || {};
                if (type === 'printContentListToPrint') {
                  resultProxy = filteredResult?.model || {};
                }
                const resultKey = resultProxy?._data?._key || '';
                if (resultKey === _key) {
                  return true;
                }
                return false;
              })(all);
              if (!existInArray) {
                all.pushObject(result);
              }
            }
          }
        })(results);
      })(accounts);
      return all;
    },
    _filterSubTabResultByTransporterType(transporterType = '', results = []) {
      return R.reduce((acc, modelOrModelProxy) => {
        const docSalesTransporterType = modelOrModelProxy?._data?.transporterType || modelOrModelProxy?.model?._data?.transporterType;
        if (docSalesTransporterType !== transporterType) {
          return acc;
        }
        return acc.concat(modelOrModelProxy);
      }, [])(results);
    },
    _subTabHasFilters(activeSubTab) {
      return R.anyPass([R.propEq('value', this.constantsService.transporterExtensions.tCat), R.propEq('value', this.constantsService.transporterExtensions.payuniSevenEleven)])(activeSubTab);
    }
  }, _applyDecoratedDescriptor(_obj, "_convertMultipleWaybillsPerPageToSingle", [_dec], Object.getOwnPropertyDescriptor(_obj, "_convertMultipleWaybillsPerPageToSingle"), _obj), _applyDecoratedDescriptor(_obj, "resetBatchStatus", [_dec2], Object.getOwnPropertyDescriptor(_obj, "resetBatchStatus"), _obj), _applyDecoratedDescriptor(_obj, "setWaybillsPerPage", [_dec3], Object.getOwnPropertyDescriptor(_obj, "setWaybillsPerPage"), _obj), _applyDecoratedDescriptor(_obj, "setupPopup", [_dec4], Object.getOwnPropertyDescriptor(_obj, "setupPopup"), _obj), _applyDecoratedDescriptor(_obj, "_filterSubTabResultByTransporterType", [_dec5], Object.getOwnPropertyDescriptor(_obj, "_filterSubTabResultByTransporterType"), _obj), _applyDecoratedDescriptor(_obj, "_subTabHasFilters", [_dec6], Object.getOwnPropertyDescriptor(_obj, "_subTabHasFilters"), _obj), _obj));
});