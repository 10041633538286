define("client/pods/components/elements/element-icon/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend((_dec = Ember._action, _dec2 = Ember._action, _obj = {
    tagName: 'span',
    classNames: ['element-icon'],
    classNameBindings: ['iconClassName'],
    stackedClassNames: 'fa-stack fa-1x',
    iconTop: '',
    iconBottom: '',
    handleMouseEnter() {
      this.set('isHover', true);
    },
    handleMouseLeave() {
      this.set('isHover', false);
    },
    didInsertElement() {
      this._super(...arguments);
      this.element.addEventListener('mouseenter', this.handleMouseEnter);
      this.element.addEventListener('mouseleave', this.handleMouseLeave);
    },
    willDestroyElement() {
      if (this?.isDestroyed === false) {
        this._super(...arguments);
        this.element.removeEventListener('mouseenter', this.handleMouseEnter);
        this.element.removeEventListener('mouseleave', this.handleMouseLeave);
      }
    },
    _tooltipTranslate: Ember.computed('tooltipTranslate', function () {
      const tooltipTranslate = this.get('tooltipTranslate');
      if (tooltipTranslate === false) {
        return false;
      }
      if (tooltipTranslate === true) {
        return true;
      }
      return this.translate;
    }),
    _icon: Ember.computed('icon', 'isHover', function () {
      if (this.isHover) {
        return this.hoverIcon || this.icon;
      }
      return this.icon;
    })
  }, _applyDecoratedDescriptor(_obj, "handleMouseEnter", [_dec], Object.getOwnPropertyDescriptor(_obj, "handleMouseEnter"), _obj), _applyDecoratedDescriptor(_obj, "handleMouseLeave", [_dec2], Object.getOwnPropertyDescriptor(_obj, "handleMouseLeave"), _obj), _obj));
});