define("client/pods/components/lists/list-searcher/component", ["exports", "ramda", "ramda-adjunct", "client/pods/components/lists/mixins/dropdown"], function (_exports, R, RA, _dropdown) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  // @NOTE:
  // what is the purpose of itemValueKey and itemLabelKey?
  // items[selectedItemValueKey] binds to model value
  // hasSelected value is either true/false UNLESS when hasSelectedKey is set.
  // hasSelected value is then bound bound to item[hasSelectedKey]
  // allowCustom: ??
  var _default = _exports.default = Ember.Component.extend(_dropdown.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _obj = {
    tagName: 'div',
    itemValueKey: '_data.value',
    itemLabelKey: '_data.label',
    selectedItemValueKey: '_data.label',
    // the item property to display for selected item
    classNames: ['list-searcher'],
    classNameBindings: ['isDropdownVisible'],
    tagClassNames: null,
    autofocus: false,
    hasDropdown: true,
    showDropdown: false,
    onSearch: null,
    // function to search on keyUp
    onDeselect: null,
    // function on deselect searcher
    readonly: false,
    disabled: false,
    hasSelected: null,
    // determines if item is a selected value (from list) or custom value
    hasSelectedKey: null,
    // will bind hasSelected to selected[hasSelectedKey]
    allowCustom: false,
    // will remove custom values if not allowed
    value: '',
    lastValue: '',
    inputIsFullW: true,
    tagAutoFocus: false,
    list: null,
    placeholder: 'search',
    showAdvancedModal: false,
    init() {
      this._super(...arguments);
      if (RA.isNilOrEmpty(this.list)) {
        this.set('list', []);
      }
      this.set('tagClassnames', ['list-searcher__tag']);
      const tagMaxChar = this.tagMaxChar || 20;
      this.set('_tagMaxChar', tagMaxChar);
    },
    /**
     * determines if dropdown should be shown.
     * only show if isDropdownVisible and has list and has focus
     */
    isDropdownVisible: Ember.computed('showDropdown', 'list.[]', function () {
      const list = this.list || [];
      if (!this.readonly && this.showDropdown && list.length > 0) {
        return true;
      }
      return false;
    }),
    focusIn() {
      this._super(...arguments);
      const value = this.value;
      const onFocusIn = this.onFocusIn;
      if (onFocusIn) {
        onFocusIn(value);
      }
    },
    focusOut() {
      this._super(...arguments);
      const value = this.value;
      const onFocusOut = this.onFocusOut;
      if (onFocusOut) {
        onFocusOut(value);
      }
      if (!this.hasSelected && value) {
        if (!this.allowCustom) {
          this.set('tagAutoFocus', false);
        }
      }
    },
    onSearchAction() {
      if (this.onSearch) {
        let simpleSearch = true;
        let value = this?.value || '';
        if (this.hasAdvancedModal) {
          if (this.showAdvancedModal === false) {
            // 191 = '?'
            if (R.includes('?', value)) {
              simpleSearch = false;
              value = R.replace('?', '', value);
              this.set('value', value);
              this.set('showAdvancedModal', true);
              this.onSearch(...arguments);
              this.set('showDropdown', false);
            }
          }
        }
        if (simpleSearch) {
          this.set('showDropdown', true);
          this.onSearch(...arguments);
        }
        this.set('lastValue', value);
        this.set('input', value);
      }
    },
    selectDropdownItem(item) {
      const selectedItemValueKey = this.selectedItemValueKey;
      if (selectedItemValueKey) {
        this.set('value', item.get(selectedItemValueKey));
        this.set('tagAutoFocus', true);
      }
      // select dropdown item, set component value
      this.set('showDropdown', false);

      // set hasSelected if allow custom = false
      /// / @TODO: test
      const hasSelectedKey = this.hasSelectedKey;
      if (hasSelectedKey) {
        this.set('hasSelected', item.get(hasSelectedKey));
      } else {
        this.set('hasSelected', true);
      }
      if (this.onSelect) {
        this.onSelect(item, this);
      }
    },
    actions: {
      // @NOTE: these actions cannot yet be removed because of dropdown mixin

      /**
       * if hasSelected and hovering over tag it should show deselect button
       */
      handleMouseEnter(self) {
        self.set('isHover', true);
        if (this.hasSelected) {
          this.set('isShowDeselectClassNames', 'list-searcher__deselect');
        } else {
          this.set('isShowDeselectClassNames', 'list-searcher__deselect--hidden');
        }
      },
      handleMouseLeave(self) {
        self.set('isHover', false);
        this.set('isShowDeselectClassNames', 'list-searcher__deselect--hidden');
      },
      hideDropdown() {
        this.set('showDropdown', false);
      },
      deselectDropdownItem(e) {
        this.set('value', '');
        this.set('tagAutoFocus', false);

        // @TODO: test
        const hasSelectedKey = this.hasSelectedKey;
        if (hasSelectedKey) {
          this.set('hasSelected', '');
        } else {
          this.set('hasSelected', false);
        }
        if (this.onDeselect) {
          this.onDeselect();
        }
      }
    },
    onToggleModalDialog() {
      this.set('showDropdown', false);
    },
    afterLoadResultModal(shouldSetZIndex) {
      const className = 'over-modal';
      if (shouldSetZIndex) {
        this.element?.classList?.add?.(className);
        return;
      }
      this.element?.classList?.remove?.(className);
    }
  }, _applyDecoratedDescriptor(_obj, "onSearchAction", [_dec], Object.getOwnPropertyDescriptor(_obj, "onSearchAction"), _obj), _applyDecoratedDescriptor(_obj, "selectDropdownItem", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectDropdownItem"), _obj), _applyDecoratedDescriptor(_obj, "onToggleModalDialog", [_dec3], Object.getOwnPropertyDescriptor(_obj, "onToggleModalDialog"), _obj), _applyDecoratedDescriptor(_obj, "afterLoadResultModal", [_dec4], Object.getOwnPropertyDescriptor(_obj, "afterLoadResultModal"), _obj), _obj));
});