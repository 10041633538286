define("client/pods/components/products/products-editor/sets-with-choices-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zkKybK68",
    "block": "{\"symbols\":[\"formInitData\",\"choice\",\"index\",\"@tab\",\"@model\",\"@isEditing\",\"@isNew\",\"@wizardContainerComponent\",\"@resourceList\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@formInitData\",\"@tab\"],[\"isSetWithChoices\",[28,\"hash\",null,[[\"documentationResource\",\"resourceList\",\"tab\",\"wizardContainerComponent\"],[\"products\",[23,9,[]],[23,4,[]],[23,8,[]]]]],[23,4,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[4,\"each\",[[23,5,[\"_data\",\"choices\"]]],null,{\"statements\":[[0,\"    \"],[5,\"forms/form-group\",[],[[\"@tab\",\"@context\",\"@formInitData\",\"@documentationResource\",\"@title\"],[[23,4,[]],\"productsSetsWithChoices_group\",[23,1,[]],\"products\",\"product_sets_with_choices_contents\"]],{\"statements\":[[0,\"\\n      \"],[5,\"products/products-editor/sets-with-choices-editor/choices-editor\",[],[[\"@choice\",\"@errorIndex\",\"@errors\",\"@model\",\"@onRemoveChoice\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@tab\"],[[23,2,[]],[23,3,[]],[22,\"errors\"],[23,5,[]],[28,\"action\",[[23,0,[]],\"removeChoice\",[23,5,[]],[23,2,[]]],null],[23,6,[]],[23,7,[]],[23,0,[\"isReadonlyAndAvailable\"]],[23,4,[]]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"\\n  \"],[5,\"forms/form-group\",[],[[\"@tab\",\"@context\",\"@formInitData\",\"@title\"],[[23,4,[]],\"productsSetsWithChoicesAddBtn_group\",[23,1,[]],\"product_sets_with_choices\"]],{\"statements\":[[0,\"\\n    \"],[5,\"forms/form-input-row\",[],[[\"@context\",\"@formInitData\",\"@isRequired\",\"@label\"],[\"choices\",[23,1,[]],true,\"\"]],{\"statements\":[[0,\"\\n      \"],[5,\"elements/element-btn\",[],[[\"@icon\",\"@label\",\"@disabled\",\"@onSubmit\"],[\"fas fa-plus\",\"add new choices\",[23,0,[\"isReadonlyAndAvailable\"]],[28,\"action\",[[23,0,[]],\"addChoice\",[23,5,[]]],null]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/sets-with-choices-editor/template.hbs"
    }
  });
});