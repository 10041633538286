define("client/pods/products/lists/display-sub-panels/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/options/model"], function (_exports, _adapter, _adaptersNew, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/app/lists/menu',
    modelObj: _model.default,
    idParam: false,
    findAll({
      data = {},
      appendPath,
      serverType
    } = {}) {
      const list = [{
        value: 'details',
        label: 'details',
        resource: 'api/protected/products/sub-panels-display/details',
        component: 'products/panels-display/details-display',
        tabType: 'reuseSubTab',
        editingComponent: 'products/products-editor/details-editor'
      }, {
        value: 'webpages',
        label: 'webpages_content',
        resource: 'api/protected/products/sub-panels-display/pages',
        component: 'products/panels-display/pages-display',
        tabType: 'reuseSubTab',
        editingComponent: 'products/products-editor/pages-editor'
      },
      // @TODO: this currently does not do anything yet.
      // this will work, but to complete feature, this needs to send data out on order success email
      // {
      //   value: 'virtual',
      //   label: 'virtual_content',
      //   resource: 'api/protected/products/sub-panels-display/virtual-contents',
      //   component: 'products/products-editor/virtual-contents-editor',
      //   editingComponent: 'products/products-editor/virtual-contents-editor',
      //   tabType: 'reuseSubTab'
      // },
      {
        value: 'seo',
        label: 'seo',
        resource: 'api/protected/products/sub-panels-display/seo',
        component: 'products/panels-display/seo-display',
        tabType: 'reuseSubTab',
        editingComponent: 'products/products-editor/seo-editor'
      }, {
        value: 'email',
        label: 'email',
        resource: 'api/protected/products/sub-panels-display/email',
        component: 'products/panels-display/email-display',
        tabType: 'reuseSubTab',
        editingComponent: 'products/products-editor/email-editor'
      }, {
        value: 'faqs',
        label: 'faqs',
        resource: 'api/protected/products/sub-panels-display/faqs',
        component: 'products/panels-display/faqs-display',
        editingComponent: 'products/products-editor/details-editor'
      }, {
        value: 'isSet',
        label: 'product_sets',
        resource: 'api/protected/products/sub-panels-display/sets',
        component: 'products/panels-display/sets-display',
        tabType: 'reuseSubTab',
        editingComponent: 'products/products-editor/sets-editor'
      }, {
        value: 'isSetWithChoices',
        label: 'product_sets_with_choices',
        resource: 'api/protected/products/sub-panels-display/sets-with-choices',
        component: 'products/panels-display/sets-with-choices-display',
        tabType: 'reuseSubTab',
        editingComponent: 'products/products-editor/sets-with-choices-editor'
      }, {
        value: 'hasVariants',
        label: 'variants',
        resource: 'api/protected/products/sub-panels-display/variants',
        component: 'products/panels-display/variants-display',
        tabType: 'reuseSubTab',
        editingComponent: 'products/products-editor/variants-editor'
      }];
      if (this.settings.getProp('beta.allowRecurringSchedules') && this.settings.getProp('features.allowRecurringSchedules')) {
        list.pushObject({
          value: 'recurringSchedules',
          label: 'recurring schedules',
          resource: 'api/protected/recurring',
          component: 'products/products-editor/recurring-schedules-editor',
          tabType: 'reuseSubTab',
          editingComponent: 'products/products-editor/recurring-schedules-editor'
        });
      }
      list.pushObject({
        value: 'customizations',
        label: 'customizations',
        resource: 'api/protected/products/sub-panels-display/customizations',
        component: 'products/products-editor/customizations-editor',
        tabType: 'reuseSubTab',
        editingComponent: 'products/products-editor/customizations-editor'
      });
      list.pushObject({
        value: 'customerActivity',
        label: 'customer activity',
        resource: 'api/protected/products/sub-panels/customer-activity',
        docType: 'sales',
        tabType: 'replaceSubTab',
        component: 'products/panels-display/activity-display',
        editingComponent: 'products/products-editor/details-editor'
      });
      list.pushObject({
        value: 'supplierActivity',
        label: 'supplier activity',
        resource: 'api/protected/products/sub-panels/supplier-activity',
        docType: 'purchases',
        tabType: 'replaceSubTab',
        component: 'products/panels-display/activity-display',
        editingComponent: 'products/products-editor/details-editor'
      });
      list.pushObject({
        value: 'activity',
        label: 'activity',
        resource: 'api/protected/products/sub-panels/transactions',
        component: 'products/panels-display/transactions-display',
        tabType: 'replaceSubTab',
        editingComponent: 'products/products-editor/details-editor'
      });
      list.pushObject({
        resource: '/api/protected/documents/data/pending',
        component: 'documents/pending/tab-container',
        label: 'doc-pending',
        tabType: 'replaceSubTab',
        docType: 'sales',
        docName: 'orders'
        // docType: 'consignment'
      });
      list.pushObject({
        value: 'shop',
        label: 'shop',
        resource: 'api/protected/products/sub-panels/shop',
        component: 'products/panels-display/shop-display',
        tabType: 'replaceSubTab'
      });

      // const isVirtualOnly = this.settings?.isFeatureAllowed('features.isVirtualOnly')
      // if (isVirtualOnly) {
      //   const allowed = [
      //     'details',
      //     'webpages',
      //     'virtual',
      //     'seo',
      //     'activity'
      //   ]
      //   list = R.filter(item => {
      //     return R.includes(item.value)(allowed)
      //   })(list)
      // }

      const customData = {
        list
      };
      const path = this.getFullPath({
        pathName: 'findAllPath',
        appendPath,
        serverType
      });
      return this.ajax.POST(path, customData).then(function (res) {
        return res.data || [];
      });
    }
  });
});