define("client/pods/components/products/panels-display/shop-display/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Xu6c/+2h",
    "block": "{\"symbols\":[\"@model\",\"@tab\"],\"statements\":[[5,\"products/products-shop-wizard/selector\",[],[[\"@model\",\"@onAfterSave\",\"@isFromShop\",\"@isReadonly\",\"@tab\",\"@hasFooterBtns\"],[[23,1,[]],[28,\"fn\",[[23,0,[\"onAfterSave\"]]],null],false,false,[23,2,[]],false]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/panels-display/shop-display/template.hbs"
    }
  });
});