define("client/pods/components/products/products-shop-wizard/editor/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda", "ramda-adjunct", "ramda-extension"], function (_exports, _component, _emberConcurrencyDecorators, R, RA, R_) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ProductsProductsShopWizardEditorComponent = _exports.default = (_dec = Ember.inject.service('application'), _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, _dec20 = Ember._tracked, _dec21 = Ember._tracked, _dec22 = Ember.computed('listsIsLoaded', 'currentMode', 'this.crud.lists.products-lists-products-shop-sub-panels.[]'), _dec23 = Ember._action, _class = class ProductsProductsShopWizardEditorComponent extends _component.default {
    constructor() {
      super(...arguments);

      // clear subTabs
      _initializerDefineProperty(this, "applicationService", _descriptor, this);
      _initializerDefineProperty(this, "campaignsProducts", _descriptor2, this);
      _initializerDefineProperty(this, "tabsManager", _descriptor3, this);
      _initializerDefineProperty(this, "crud", _descriptor4, this);
      _initializerDefineProperty(this, "constants", _descriptor5, this);
      _initializerDefineProperty(this, "listsIsLoaded", _descriptor6, this);
      _initializerDefineProperty(this, "selectedStep", _descriptor7, this);
      _initializerDefineProperty(this, "campaign", _descriptor8, this);
      _initializerDefineProperty(this, "campaignDetail", _descriptor9, this);
      _initializerDefineProperty(this, "campaignDetailAdapterName", _descriptor10, this);
      _initializerDefineProperty(this, "drafts", _descriptor11, this);
      _initializerDefineProperty(this, "results", _descriptor12, this);
      _initializerDefineProperty(this, "filters", _descriptor13, this);
      _initializerDefineProperty(this, "filtersForDrafts", _descriptor14, this);
      _initializerDefineProperty(this, "productStep", _descriptor15, this);
      _initializerDefineProperty(this, "currentDraftModel", _descriptor16, this);
      _initializerDefineProperty(this, "tabCopy", _descriptor17, this);
      _initializerDefineProperty(this, "autoSelectFirstTab", _descriptor18, this);
      _initializerDefineProperty(this, "showPricelists", _descriptor19, this);
      _initializerDefineProperty(this, "hasSaved", _descriptor20, this);
      _initializerDefineProperty(this, "currentMode", _descriptor21, this);
      const tab = this.args.tab;
      tab.resetTabs();
      this.crud.addLists(this, ['products/lists/products-shop-sub-panels']);
      this.autoSelectFirstTab = false;
    }
    get subPanels() {
      const currentMode = this.currentMode;
      const panels = this.crud.lists['products-lists-products-shop-sub-panels'] || [];
      const panelsForMode = R.filter(panel => {
        const allowedFor = panel?._data?.allowedFor || [];
        return R.includes(currentMode)(allowedFor);
      })(panels);
      return panelsForMode;
    }
    afterLoadLists() {
      const panels = this.subPanels || [];
      const currentMode = this.currentMode;
      const selectedPanelOptions = R.find(R.pathEq(['_data', 'value'], currentMode))(panels);
      this.selectStepTask.perform(selectedPanelOptions);
    }
    *addProductToShopAndEnterEditCampaignDetail(product, tabOptions) {
      if (!this.args.onAddProductToShop) {
        return;
      }
      const campaignDetail = yield this.args.onAddProductToShop.perform(product, true);
      this.campaignDetail = campaignDetail;
      return yield this.convertToCampaignDetailEditModeTask.perform();
    }
    *convertToCampaignDetailEditModeTask() {
      const mode = 'campaignDetail';
      this.currentMode = mode;
      const panels = this.subPanels || [];
      const selectedPanelOptions = R.find(R.pathEq(['_data', 'value'], mode))(panels);
      yield this.selectStepTask.perform(selectedPanelOptions);
    }
    displayCampaignDetailEditor(selectedPanelOptions) {
      // @TODO: fix later. not sure why this has problems without the scheduleOnce
      setTimeout(() => {
        const tab = this.args.tab;
        this.selectedCampaignModel = this.args.campaign;
        const campaignDetail = this.campaignDetail;
        const panelOptions = R.pipe(R.propOr({}, '_data'), R.mergeRight({
          model: campaignDetail
        }))(selectedPanelOptions);
        this.tabsManager.replaceSubTab(tab, panelOptions);
      }, 10);
    }
    *displayProductsEditorTask(selectedPanelOptions) {
      const tab = this.args.tab;
      const campaign = this.args.campaign;
      this.selectedCampaignModel = campaign;
      const campaignDetail = this.campaignDetail;
      let model;
      if (RA.isNotNilOrEmpty(campaignDetail)) {
        const product = campaignDetail.product;
        const productKey = product?._data?._key;
        model = yield this.crud.findTask.perform({
          adapterName: 'products',
          appendPath: productKey
        });
      }
      const panelOptions = R.pipe(R.propOr({}, '_data'), R.mergeRight({
        model
      }))(selectedPanelOptions);
      this.tabsManager.replaceSubTab(tab, panelOptions);
    }
    get isEditing() {
      const isEditing = this.args.isEditing;
      if (isEditing) {
        return true;
      }
      if (this.hasSaved) {
        return true;
      }
      return false;
    }
    *selectStepTask(panelOptions) {
      const previousStep = R.clone(this.selectedStep?._data || {});
      if (previousStep?.value === panelOptions?._data?.value) {
        // did not change steps
        return;
      }
      const selectedStep = panelOptions?._data?.value;
      this.selectedStep = panelOptions;
      if (selectedStep === 'campaignDetail') {
        this.displayCampaignDetailEditor(panelOptions);
      } else {
        this.displayProductsEditorTask.perform(panelOptions);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "applicationService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignsProducts", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabsManager", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "constants", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "listsIsLoaded", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "selectedStep", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "campaign", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.campaign;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "campaignDetail", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.campaignDetail;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "campaignDetailAdapterName", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.campaignDetailAdapterName;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "drafts", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "results", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return Ember.A([]);
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "filtersForDrafts", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "productStep", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "currentDraftModel", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "tabCopy", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "autoSelectFirstTab", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "showPricelists", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "hasSaved", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "currentMode", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.mode;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "subPanels", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "subPanels"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addProductToShopAndEnterEditCampaignDetail", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "addProductToShopAndEnterEditCampaignDetail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "convertToCampaignDetailEditModeTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "convertToCampaignDetailEditModeTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayCampaignDetailEditor", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "displayCampaignDetailEditor"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayProductsEditorTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "displayProductsEditorTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectStepTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "selectStepTask"), _class.prototype), _class);
});