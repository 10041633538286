define("client/pods/components/modals/modal-container-new/component", ["exports", "@glimmer/component", "ramda", "ramda-adjunct", "client/utils/nventor"], function (_exports, _component, R, RA, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ModalsModalContainerNewComponent = _exports.default = (_dec = Ember.inject.service('modals'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _class = class ModalsModalContainerNewComponent extends _component.default {
    // .tab-grid__modal = 3001/6000
    // .layouts__left-bar .u-z-index--4000 = 4000
    // .app-grid__header-fix = 5000
    // .gjs-fullscreen = 6000
    // .tab-grid__modal--fullscreen = 10002

    // on fullscreen
    // modalContainerNew should > 10002 for modal on fullscreen
    // navbar should < 6000

    // on normal screen
    // modalContainerNew should < 4000 for sidebar
    // modalContainerNew should < 5000 for navbar
    // modalContainerNew should > 3001/6000 for modal dialog

    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modalsService", _descriptor, this);
      _initializerDefineProperty(this, "modalId", _descriptor2, this);
      _initializerDefineProperty(this, "showDialog", _descriptor3, this);
      _initializerDefineProperty(this, "footerHeight", _descriptor4, this);
      _initializerDefineProperty(this, "headerHeight", _descriptor5, this);
      _initializerDefineProperty(this, "modalOverLayZIndex", _descriptor6, this);
      // navbar 5000, sidebar 4000, sidebar overlay 3000, modal 6000
      _initializerDefineProperty(this, "modalDialogZIndex", _descriptor7, this);
      _initializerDefineProperty(this, "modalOverLayZIndexOnFullscreen", _descriptor8, this);
      _initializerDefineProperty(this, "modalDialogZIndexOnFullScreen", _descriptor9, this);
      _defineProperty(this, "isAlreadyClosed", false);
      this.modalId = _nventor.default.random.alpha(3) + _nventor.default.random.alphaNum(10);
      const isSidebar = this.args?.isSidebar || false;
      if (isSidebar) {
        window.addEventListener('resize', this.setupModal);
      }
    }
    get isShowOverlay() {
      // const closeOnOverlayClick = R.pathOr(true, ['args', 'closeOnOverlayClick'])(this)
      const hasModalOverlay = R.pathOr(true, ['args', 'hasModalOverlay'])(this);
      return hasModalOverlay;
    }
    setupModal({
      moveToModalSection = true
    } = {}) {
      const parentElement = this.args.parentElement;
      this.modalsService.setupModal({
        id: this.modalId,
        headerHeight: this.headerHeight,
        footerHeight: this.footerHeight,
        modalPos: this.args.modalPos,
        modalContainerClassNames: this.args.modelContainerClassNames,
        modalDialogZIndex: this.modalDialogZIndex,
        modalOverLayZIndex: this.modalOverLayZIndex,
        modalDialogZIndexOnFullScreen: this.modalDialogZIndexOnFullScreen,
        modalOverLayZIndexOnFullscreen: this.modalOverLayZIndexOnFullscreen,
        isSidebar: this.args.isSidebar,
        hasExtraSidebar: this.args.hasExtraSidebar,
        sidebarModalMaxWidth: this.args.sidebarModalMaxWidth,
        isMobile: this.args?.isMobile,
        moveToModalSection,
        parentElement,
        debug: this.args.debug
      });
      if (this.args?.onAfterLoad) {
        this.args?.onAfterLoad?.();
      }
    }
    handleMouseEnter(e) {
      if (this.args.mouseEnter) {
        this.args.mouseEnter(e);
      }
    }
    handleMouseLeave(e) {
      if (this.args.mouseLeave) {
        this.args.mouseLeave(e);
      }
    }
    closeOverlay() {
      const onClose = this.args?.onClose;
      if (onClose) {
        this.isAlreadyClosed = true;
        this.args.onClose();
      }
    }
    willDestroyElement() {
      if (this.isDestroyed) {
        return;
      }
      window.removeEventListener('resize', this.setupModal);
      const onClose = this.args?.onClose;
      if (onClose && !this.isAlreadyClosed) {
        this.args.onClose();
      }
      const overlay = document.getElementById(`overlay-${this.modalId}`);
      if (overlay) {
        overlay.remove();
      }
      const modalDialog = document.getElementById(this.modalId);
      if (modalDialog) {
        modalDialog.remove();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "modalsService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modalId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showDialog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "footerHeight", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 61;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "headerHeight", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 46;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "modalOverLayZIndex", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 5498;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "modalDialogZIndex", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 5499;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "modalOverLayZIndexOnFullscreen", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 10005;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "modalDialogZIndexOnFullScreen", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 10006;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupModal", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "setupModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseEnter", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMouseLeave", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseLeave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeOverlay", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "closeOverlay"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyElement", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyElement"), _class.prototype), _class);
});