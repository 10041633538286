define("client/pods/components/channels/shop/campaigns/panels-edit/component", ["exports", "client/mixins/crud", "ramda-extension", "ramda-adjunct", "ember-concurrency"], function (_exports, _crud, R_, RA, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  var _default = _exports.default = Ember.Component.extend(_crud.default, (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _obj = {
    crud: Ember.inject.service(),
    adapterName: 'channels/shop/campaigns',
    init() {
      this._super(...arguments);
      this.setup();
    },
    setup() {
      this.crud.addLists(this, ['channels/shop/campaigns/lists/subPanels', 'channels/shop/campaigns/lists/editorSubPanels', 'channels/shop/campaigns/lists/settings-editor/editor-sub-panels']);
      const model = this.get('tab.model');
      const dirty = this.setupDirty({
        model
      });
      this.set('dirty', dirty);

      // if (!dirty.getData('password')) {
      //   const password = nventor.random.alphaNum(8).toLowerCase()
      //   dirty.setData('password', password)
      // }

      this.tabsManager.setHeader(this.tabParent, model.getData('name'));
    },
    _displayCampaign(model) {
      const tab = this.tab;
      const loadInstructionsForActivePanels = this.tabsManager.getLoadInstructionsForActivePanels(tab, [{
        matchBy: 'value',
        componentPath: '_data.component',
        menu: R_.dotPath('crud.lists.channels-shop-campaigns-lists-subPanels')(this) || []
      }, {
        matchBy: 'value',
        componentPath: '_data.component',
        menu: R_.dotPath('crud.lists.channels-shop-campaigns-lists-settings-editor-editor-sub-panels')(this)
      }]);
      const tabOptions = {
        loadInstructionsForActivePanels
      };
      return this.onDisplay(model, {
        tabOptions
      });
    },
    afterUpdateRecordTask(saved) {
      this._displayCampaign(saved);
    },
    afterReplaceRecordTask(saved) {
      this._displayCampaign(saved);
    },
    afterCancelEditing(original) {
      this._displayCampaign(original);
    },
    onSaveTask: (0, _emberConcurrency.task)(function* () {
      yield this.crud.replaceRecordTask.perform({
        component: this,
        adapterName: this.adapterName,
        model: this.dirty,
        tab: this.tab,
        onAfter: this.afterReplaceRecordTask
      });
    }),
    cancel(dirty) {
      this.crud.cancelEditing({
        adapterName: this.adapterName,
        dirty: dirty,
        onAfter: this._displayCampaign
      });
    }
  }, _applyDecoratedDescriptor(_obj, "_displayCampaign", [_dec], Object.getOwnPropertyDescriptor(_obj, "_displayCampaign"), _obj), _applyDecoratedDescriptor(_obj, "afterUpdateRecordTask", [_dec2], Object.getOwnPropertyDescriptor(_obj, "afterUpdateRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterReplaceRecordTask", [_dec3], Object.getOwnPropertyDescriptor(_obj, "afterReplaceRecordTask"), _obj), _applyDecoratedDescriptor(_obj, "afterCancelEditing", [_dec4], Object.getOwnPropertyDescriptor(_obj, "afterCancelEditing"), _obj), _applyDecoratedDescriptor(_obj, "cancel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "cancel"), _obj), _obj));
});