define("client/pods/components/products/products-editor/sets-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2ISP7w65",
    "block": "{\"symbols\":[\"formInitData\",\"detail\",\"index\",\"@isEditing\",\"@isNew\",\"@model\",\"@tab\",\"@wizardContainerComponent\",\"@resourceList\"],\"statements\":[[5,\"forms/form-container\",[],[[\"@containerId\",\"@formInitData\",\"@tab\"],[\"isSet\",[28,\"hash\",null,[[\"documentationResource\",\"resourceList\",\"tab\",\"wizardContainerComponent\"],[\"products\",[23,9,[]],[23,7,[]],[23,8,[]]]]],[23,7,[]]]],{\"statements\":[[0,\"\\n\"],[0,\"\\n\\t\"],[5,\"forms/form-group\",[],[[\"@context\",\"@formInitData\",\"@tab\",\"@title\"],[\"productSetsContents_group\",[23,1,[]],[23,7,[]],\"product_sets_contents\"]],{\"statements\":[[0,\"\\n\\t\\t\"],[7,\"table\",true],[10,\"class\",\"table-form u-full-width\"],[8],[0,\"\\n\\t\\t\\t\"],[5,\"products/products-editor/sets-editor/details-editor\",[],[[\"@isEditing\",\"@isHeaders\",\"@isNew\"],[[23,4,[]],true,[23,5,[]]]]],[0,\"\\n\\n\"],[4,\"each\",[[23,6,[\"_data\",\"setDetails\"]]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[5,\"products/products-editor/sets-editor/details-editor\",[],[[\"@detail\",\"@errors\",\"@errorIndex\",\"@isEditing\",\"@isNew\",\"@isReadonly\",\"@model\",\"@onLoadProduct\",\"@onRemoveSetDetail\",\"@onResetSetDetail\",\"@tab\",\"@index\"],[[23,2,[]],[22,\"errors\"],[23,3,[]],[23,4,[]],[23,5,[]],[23,0,[\"isReadonlyAndAvailable\"]],[23,6,[]],[28,\"action\",[[23,0,[]],\"loadProduct\"],null],[28,\"action\",[[23,0,[]],\"removeSetDetail\"],null],[28,\"action\",[[23,0,[]],\"resetSetDetail\"],null],[23,7,[]],[23,3,[]]]]],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"\\t\\t\"],[9],[0,\"\\n\\n\\t\\t\"],[5,\"elements/error-wrapper\",[],[[\"@context\"],[\"setDetails\"]]],[0,\"\\n\\t\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]}],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/products/products-editor/sets-editor/template.hbs"
    }
  });
});