define("client/pods/components/helpers/drop-container/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iEM4wCta",
    "block": "{\"symbols\":[\"&default\",\"@hasDropContainerPlaceholders\"],\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"drop-container-placeholder-container flex-container-row flex-container--align-items-center\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"drop-container-placeholder drop-container-placeholder__left\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"drop-container-placeholder__left-inner\"],[8],[0,\"\\n\"],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"drop-container-placeholder__center\"],[8],[0,\"\\n      \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"drop-container-placeholder drop-container-placeholder__right\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"drop-container-placeholder__right-inner\"],[8],[0,\"\\n\"],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/helpers/drop-container/template.hbs"
    }
  });
});