define("client/pods/components/blogs/table-view/detail-row/component", ["exports", "@glimmer/component", "ember-concurrency-decorators", "ramda"], function (_exports, _component, _emberConcurrencyDecorators, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BlogsTableViewDetailRowComponent = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._action, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _class = class BlogsTableViewDetailRowComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "crud", _descriptor, this);
      _initializerDefineProperty(this, "dragAndDrop", _descriptor2, this);
      _initializerDefineProperty(this, "dropPosition", _descriptor3, this);
      _initializerDefineProperty(this, "showUploadsRow", _descriptor4, this);
      _initializerDefineProperty(this, "isReadyMode", _descriptor5, this);
      _initializerDefineProperty(this, "isEditMode", _descriptor6, this);
      _initializerDefineProperty(this, "modelProxy", _descriptor7, this);
      _defineProperty(this, "isDraggable", true);
      _defineProperty(this, "isDroppable", true);
      _defineProperty(this, "adapterName", 'blogs');
      this.crud.addLists(this, ['blogs/lists/menu', 'blogs/lists/tags', 'statuses']);
      this.modelProxy = this.args.model;
    }
    get rowClassName() {
      return `table-view__detail-row ${this.dropPosition}`;
    }
    get description() {
      const div = document.createElement('div');
      div.innerHTML = R.pathOr('', ['args', 'model', '_data', 'description'])(this);
      return div.innerText;
    }
    get updateRecordIndexOnDrop() {
      return R.pathOr(true, ['args', 'tableViewOptions', 'updateRecordIndexOnDrop'])(this);
    }
    get isSelected() {
      const selectedItems = R.pathOr([], ['args', 'tableViewOptions', 'selectedItems'])(this);
      if (selectedItems.indexOf(this.args.model) > -1) {
        return true;
      }
      return false;
    }
    *updateRecordTask() {
      this.modelProxy = yield this.crud.updateRecordTask.perform({
        adapterName: this.adapterName,
        model: this.modelProxy,
        tab: this.args.tab
      });
      this.cancelDirty();
    }
    setupDirty() {
      this.modelProxy = this.crud.setupDirty({
        model: this.args.model,
        adapterName: this.adapterName
      });
      this.isReadyMode = false;
      this.isEditMode = true;
    }
    cancelDirty() {
      if (this.modelProxy.isDirty) {
        this.crud.cancelEditing({
          adapterName: this.adapterName,
          dirty: this.modelProxy
        });
      }
      this.isReadyMode = true;
      this.isEditMode = false;
    }
    drop(indexStart, indexEnd, results) {
      let reIndexBlogs;
      if (indexStart > indexEnd) {
        reIndexBlogs = R.slice(indexEnd, R.inc(indexStart))(results);
      } else {
        reIndexBlogs = R.slice(indexStart, R.inc(indexEnd))(results);
      }
      const master = R.view(R.lensPath([0, '_data', 'master']))(results);
      if (this.updateRecordIndexOnDrop) {
        this.crud.adapterCallTask.perform('updateIndexes', {
          adapterName: 'blogs/details/batch',
          appendPath: `/${master}/indexes`,
          model: reIndexBlogs
        });
      }
    }
    dragOver() {
      if (this.args.tableViewOptions.onDragOver) {
        this.dropPosition = this.args.tableViewOptions.onDragOver(...arguments);
      }
    }
    dragStart() {
      if (this.args.tableViewOptions.onDragStart) {
        this.args.tableViewOptions.onDragStart(...arguments);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "crud", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dragAndDrop", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dropPosition", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showUploadsRow", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isReadyMode", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isEditMode", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "modelProxy", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateRecordTask", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class.prototype, "updateRecordTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupDirty", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "setupDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelDirty", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "cancelDirty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "drop", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "drop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragStart", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "dragStart"), _class.prototype), _class);
});