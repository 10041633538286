define("client/pods/components/documents/payments-status-tag/component", ["exports", "client/mixins/doc-status", "client/mixins/date", "ember-concurrency", "ramda"], function (_exports, _docStatus, _date, _emberConcurrency, R) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_date.default, _docStatus.default, {
    crud: Ember.inject.service(),
    intl: Ember.inject.service(),
    constants: Ember.inject.service(),
    dateService: Ember.inject.service('date'),
    tagName: '',
    showDropdown: false,
    autoFetchLists: true,
    readonly: false,
    autoPosition: true,
    init() {
      this._super(...arguments);
      if (!this.dispatchProcesses && this.autoFetchLists) {
        this.crud.addLists(this, ['dispatch/lists/processes']);
      }
    },
    hasCreditCardTransactionCode: Ember.computed('model._data.paymentCreditCardTransactionCode', function () {
      const paymentCreditCardTransactionCode = this?.model?._data?.paymentCreditCardTransactionCode;
      if (paymentCreditCardTransactionCode) {
        return true;
      }
      return false;
    }),
    isCreditCard: Ember.computed('model._data.paymentMethodKey', function () {
      const isPaymentMethodKeyCreditCard = R.pathEq(['model', '_data', 'paymentMethodKey'], this.constants.paymentMethods.creditCard)(this);
      if (isPaymentMethodKeyCreditCard) {
        return true;
      }
      return false;
    }),
    isVirtualAccount: Ember.computed('model._data.paymentMethodKey', 'model._data.paymentData.virtualAccountNumber', function () {
      if (this.isCreditCard) {
        return false;
      }
      const virtualAccountNumber = this?.model?._data?.paymentData?.virtualAccountNumber;
      if (virtualAccountNumber) {
        return true;
      }
      return false;
    }),
    statusClassName: Ember.computed('status', function () {
      const status = this.status;
      if (status === 'approved') {
        return 'is-success';
      }
      if (status === 'refunded') {
        return 'is-danger';
      }
      return 'is-warning';
    }),
    virtualAccountStatusLabel: Ember.computed('model._data.paymentData.{status,paymentAmount}', function () {
      const status = this.model?._data?.paymentData?.status;
      const statusLabel = this.intl.t(`atm_${status}`);
      const paymentAmount = this.model?._data?.paymentData?.paymentAmount || '';
      if (!paymentAmount) {
        return statusLabel;
      }
      if (status === 'approved') {
        return statusLabel;
      }
      return `${this.intl.t('paid')}: $ ${this.intl.formatNumber(paymentAmount)} (${statusLabel})`;
    }),
    virtualAccountStatusClassName: Ember.computed('model._data.paymentData.status', function () {
      const status = this.model?._data?.paymentData?.status;
      if (status === 'approved') {
        return 'is-success';
      }
      if (status === 'attentionRequired') {
        return 'is-danger';
      }
      return 'is-warning';
    }),
    virtualAccountIsIncorrectAmountPaidAndAttentionRequired: Ember.computed('model._data.paymentData.{status,paymentAmount,paymentDifference}', function () {
      const paymentDifference = parseInt(this.model?._data?.paymentData?.paymentDifference) || 0;
      const paymentAmount = parseInt(this.model?._data?.paymentData?.paymentAmount) || 0;
      if (this.model?._data?.paymentData.status === 'attentionRequired' && paymentAmount > 0 && paymentDifference !== 0) {
        return true;
      }
      return false;
    }),
    virtualAccountIsExpiredAndAttenionRequired: Ember.computed('model._data.paymentData.{status,paymentAmount,paymentDifference}', function () {
      const paymentDifference = parseInt(this.model?._data?.paymentData?.paymentDifference) || 0;
      const paymentAmount = parseInt(this.model?._data?.paymentData?.paymentAmount) || 0;
      if (this.model?._data?.paymentData.status === 'attentionRequired' && paymentAmount > 0 && paymentDifference === 0) {
        return true;
      }
      return false;
    }),
    isDropdownVisible: Ember.computed('showDropdown', 'isBtn', function () {
      // const list = this.get('list') || []
      if (!this.isBtn) {
        return false;
      }
      if (this.readonly === false && this.showDropdown) {
        // if (this.get('readonly') === false && this.get('showDropdown') && list.length > 0) {
        return true;
      }
      return false;
    }),
    translatedDispatchProcess: Ember.computed('model._data.dispatchProcessLabel', 'crud.lists.dispatch-lists-processes.[]', 'dispatchProcesses.[]', function () {
      // translatedDispatchProcess: computed('model._data.dispatchProcess', 'crud.lists.dispatch-lists-processes.[]', 'dispatchProcesses.[]', function () {
      // const model = this.get('model')
      const dispatchProcessLabel = this.get('model._data.dispatchProcessLabel');
      const dispatchProcessLabelTranslate = this.get('model._data.dispatchProcessLabelTranslate');
      if (dispatchProcessLabelTranslate) {
        return this.intl.t(dispatchProcessLabel);
      }
      return dispatchProcessLabel;
    }),
    label: Ember.computed('model._data.{status,dispatchProcess,dispatchDateZ}', 'translatedDispatchProcess', function () {
      // @TODO: move to model?
      const model = this.model;
      const status = model.getData('status');
      const intl = this.intl;
      let label = intl.t(status);
      if (this.get('model._data.isMerged')) {
        return intl.t('merged_document');
      }
      if (!this.get('model.hasDispatchProcess')) {
        return label;
      }
      const dispatchProcess = model.getData('dispatchProcess');
      const dispatchDateZ = model.getData('dispatchDateZ');
      if (status === this.get('constants.documentsStatus.final')) {
        label = this.translatedDispatchProcess;
        if (dispatchProcess === this.get('constants.dispatchProcess.dispatched')) {
          const date = this.formatDate(dispatchDateZ);
          label = `${label}: ${date}`;
        }
      }
      if (!label) {
        label = intl.t(this.get('constants.dispatchProcess.unprepared'));
      }
      return label;
    }),
    paymentTransactionOverDue: Ember.computed('model._data.{paymentCreditCardProvider,paymentCreditCardTransactionTimestampZ}', function () {
      const paymentCreditCardProvider = R.pathOr('', ['model', '_data', 'paymentCreditCardProvider'])(this);
      if (paymentCreditCardProvider === 'tapPay') {
        const overDueAfter = 20; //Minutes
        const now = this.dateService.getMoment().subtract(overDueAfter, 'm');
        const paymentCreditCardTransactionTimestampZ = R.pipe(R.pathOr('', ['model', '_data', 'paymentCreditCardTransactionTimestampZ']), time => this.dateService.getMoment(time))(this);
        return now.isAfter(paymentCreditCardTransactionTimestampZ);
      }
      return null;
    }),
    /**
     * @deprecated
     */
    allowedPaymentMethod: Ember.computed('model._data.paymentMethodKey', function () {
      const isCreditCard = R.pathEq(['model', '_data', 'paymentMethodKey'], this.get('constants.paymentMethods.creditCard'))(this);
      const isLinePay = R.pathEq(['model', '_data', 'paymentMethodKey'], this.get('constants.paymentMethods.linePay'))(this);
      if (isCreditCard || isLinePay) {
        return true;
      }
      return false;
    }),
    allowedDocTypeAndDocName: Ember.computed('model._data.{docName,docType}', function () {
      const isDocNameInvoices = R.pathEq(['model', '_data', 'docName'], 'invoices')(this);
      const isDocTypeSales = R.pathEq(['model', '_data', 'docType'], 'sales')(this);
      if (isDocNameInvoices && isDocTypeSales) {
        return true;
      }
      return false;
    }),
    updatePaymentStatusTask: (0, _emberConcurrency.task)(function* (onCloseDialogAction, dirty, paymentStatus, onAfter) {
      dirty.setData('paymentData.status', paymentStatus);
      yield this.crud.updateRecordTask.perform({
        adapterName: 'documents/sales/invoices',
        model: dirty,
        appendPath: '/virtual-account/payment-status'
      });
      if (onAfter) {
        onAfter();
      }
      onCloseDialogAction();
    }),
    actions: {
      hideDropdown() {
        this.set('showDropdown', false);
      },
      showDropdown() {
        this.set('showDropdown', true);
      },
      submit() {
        const onSubmit = this.onSubmit;
        if (onSubmit) {
          onSubmit(...arguments);
        }
      }
    }
  });
});