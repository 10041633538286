define("client/pods/payment-methods/adapter", ["exports", "@ember-data/adapter", "client/mixins/adapters-new", "client/pods/payment-methods/model", "ramda", "client/utils/nventor"], function (_exports, _adapter, _adaptersNew, _model, R, _nventor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _adapter.default.extend(_adaptersNew.default, {
    resourcePath: 'api/protected/payment-methods',
    modelObj: _model.default,
    idParam: '_id',
    paymentsService: Ember.inject.service('payments'),
    async afterFindAll(responseData) {
      // Filter out un-enabled payment methods. eg LinePay, ApplePay, GooglePay, SamsungPay
      const validatedPaymentMethods = this.paymentsService.validatePaymentMethods(responseData);
      return R.map(data => _nventor.default.createModel(_model.default, data))(validatedPaymentMethods);
    }
  });
});